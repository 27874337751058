import _ from 'lodash';
import AEGON from './AEGON.svg';
import ASN from './ASN.svg';
import AbnAmro from './AbnAmro.svg';
import Adaxio from './Adaxio.svg';
import Allianz from './Allianz.svg';
import Argenta from './Argenta.svg';
import Attens from './Attens.png';
import BLG from './BLG.svg';
import CMIS from './CMIS.png';
import CentraalBeheer from './CentraalBeheer.svg';
import Colibri from './Colibri.svg';
import Consumentenbond from './Consumentenbond.svg';
import DAK from './DAK.svg';
import Hypotheekadviseur from './DeHypotheekadviseur.svg';
import DeHypotheker from './DeHypothekerAlt.svg';
import DeNederlandse from './DeNederlandse.svg';
import Dynamic from './Dynamic.png';
import EBP from './EBP.svg';
import EasySwitchKizi from './EasySwitchKizi.svg';
import Ennatuurlijk from './Ennatuurlijk.svg';
import FDC from './FDC.svg';
import FinancieelFit from './FinancieelFit.svg';
import FinancieelZeker from './FinancieelZeker.png';
import Finzie from './Finzie.svg';
import Florius from './Florius.svg';
import Frits from './Frits.svg';
import GavoorA from './GaVoorA.svg';
import GroeneHart from './GroeneHartCustom.svg';
import HPHypotheken from './HPHypotheken.svg';
import Handelsbanken from './Handelsbanken.svg';
import HollandWoont from './HollandWoont.svg';
import HomeInvest from './HomeInvest.svg';
import HuisHypotheek from './HuisHypotheek.svg';
import Huismerk from './Huismerk.svg';
import Hypokeur from './Hypokeur.svg';
import Hypotheek24 from './Hypotheek24.png';
import HypotheekBerekenen from './HypotheekBerekenen.svg';
import HypotheekVisie from './HypotheekVisie.svg';
import Hypotheekshop from './Hypotheekshop.svg';
import Hypotrust from './Hypotrust.png';
import ING from './ING.svg';
import IQWOON from './IQWOON.png';
import ImpactHypotheken from './ImpactHypotheken.svg';
import JIJENWIJ from './JIJENWIJ.svg';
import Lloyds from './Lloyds.svg';
import Lot from './Lot.svg';
import MUNT from './MUNT.svg';
import Merius from './Merius.svg';
import Moneyou from './Moneyou.svg';
import NBG from './NBG.svg';
import NIBC from './NIBC.svg';
import Obvion from './Obvion.png';
import OfferteAdviseur from './OfferteAdviseur.png';
import Onderlinge from './Onderlinge.png';
import OverstappenKizi from './OverstappenKizi.svg';
import Philips from './Philips.png';
import Promodomo from './Promodomo.svg';
import PwC from './PwC.svg';
import RISK from './RISK.svg';
import Rabobank from './Rabobank.svg';
import RegioBank from './RegioBank.svg';
import Robuust from './Robuust.svg';
import SNS from './SNS.svg';
import Slimster from './Slimster.png';
import Solvari from './Solvari.svg';
import Syntrus from './Syntrus.svg';
import Tellius from './Tellius.png';
import Triodos from './Triodos.svg';
import Tulp from './Tulp.png';
import VEH from './VEH.svg';
import VanBruggen from './VanBruggen.svg';
import VanLanschot from './VanLanschot.svg';
import VanLanschotKempen from './VanLanschotKempen.svg';
import Veldsink from './Veldsink.svg';
import Venn from './Venn.svg';
import Verbrugge from './Verbrugge.svg';
import Viisi from './Viisi.svg';
import Vista from './Vista.png';
import Volksbank from './Volksbank.svg';
import Welke from './Welke.svg';
import WoonNu from './WoonNu.png';
import Woonfonds from './Woonfonds.png';
import ASR from './asr.svg';
import bijBouwe from './bijBouwe.svg';
import NN from './nn.svg';
import OfferteNL from './offerteNL.svg';

const partnerLogos: { [x: string]: string } = {
  AbnAmro,
  Adaxio,
  AEGON,
  Allianz,
  Argenta,
  ASN,
  ASR,
  Attens,
  bijBouwe,
  BLG,
  CentraalBeheer,
  CMIS,
  Colibri,
  Consumentenbond,
  DAK,
  DeHypotheker,
  DeNederlandse,
  Dynamic,
  EasySwitchKizi,
  EBP,
  Ennatuurlijk,
  FDC,
  FinancieelFit,
  FinancieelZeker,
  Finzie,
  Florius,
  Frits,
  GavoorA,
  GroeneHart,
  Handelsbanken,
  HollandWoont,
  HomeInvest,
  HPHypotheken,
  HuisHypotheek,
  Huismerk,
  Hypokeur,
  Hypotheek24,
  Hypotheekadviseur,
  HypotheekBerekenen,
  Hypotheekshop,
  HypotheekVisie,
  Hypotrust,
  ImpactHypotheken,
  ING,
  IQWOON,
  JIJENWIJ,
  Lloyds,
  Lot,
  Merius,
  Moneyou,
  MUNT,
  NBG,
  NIBC,
  NN,
  Obvion,
  OfferteAdviseur,
  OfferteNL,
  Onderlinge,
  OverstappenKizi,
  Philips,
  Promodomo,
  PwC,
  Rabobank,
  RegioBank,
  RISK,
  Robuust,
  Slimster,
  SNS,
  Solvari,
  Syntrus,
  Tellius,
  Triodos,
  Tulp,
  VanBruggen,
  VanLanschot,
  VanLanschotKempen,
  VEH,
  Veldsink,
  Venn,
  Verbrugge,
  Viisi,
  Vista,
  Volksbank,
  Welke,
  Woonfonds,
  WoonNu,
};

/** This uses the actual name of the company as the key, so we can find it by looking at partnerCompany.name */
const partnerCompanyLogos: { [x: string]: string } = {
  '100% Hypotheken': HPHypotheken,
  'a.s.r.': ASR,
  'ABN AMRO': AbnAmro,
  'Groene Hart Hypotheken': GroeneHart,
  'De Hypotheekshop': Hypotheekshop,
  'De Nederlandse': DeNederlandse,
  'Financieel Fit': FinancieelFit,
  'Financieel Zeker': FinancieelZeker,
  'Huis & Hypotheek': HuisHypotheek,
  'Hypotheek Visie': HypotheekVisie,
  'Impact Hypotheken': ImpactHypotheken,
  'MUNT Hypotheken': MUNT,
  'NIBC Direct': NIBC,
  'Offerte.nl': OfferteNL,
  'Triodos Bank': Triodos,
  'Van Lanschot': VanLanschot,
  'Van Lanschot Kempen': VanLanschotKempen,
  'Vereniging Eigen Huis': VEH,
  'Venn Hypotheken': Venn,
  'Vista Hypotheken': Vista,
  'Viisi Hypotheken': Viisi,
};

export function getPartnerLogo(name?: string | null): string {
  if (!name) return '';

  // Check if the name is a key in the partnerLogos object
  const partnerKey =
    _.findKey(
      partnerLogos,
      (_, key) => key === name || key.toLowerCase() === name.toLowerCase().replace(/ /g, ''),
    ) || name;
  const partnerCompany =
    _.findKey(
      partnerCompanyLogos,
      (_, key) => key === name || key.toLowerCase() === name.toLowerCase().replace(/ /g, ''),
    ) || name;
  return partnerLogos[partnerKey] || partnerCompanyLogos[partnerCompany];
}

export default partnerLogos;
